import React from "react";
import "./App.css";
import { Amplify, Auth } from "aws-amplify";
import { ThemeProvider } from "@mui/material/styles";
// import awsconfig from "./aws-exports";

// Amplify.configure(awsconfig);
import wheel from "./assets/Subject.png";
import MenuAppBar from "./components/HeaderAppBar";
import { Button } from "@mui/material";
import { theme } from "./utils/theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <MenuAppBar />

        <header className="App-header">
          <img src={wheel} className="App-logo" alt="logo" />
          <h2>Do You Concur? If so</h2>
          <Button variant="contained" size="large">
            sign up
          </Button>
        </header>
      </div>
    </ThemeProvider>
  );
}

export default App;
